<template>
  <div class="login-page text-right">

    <div class="login-page-content-wrap px-4 mx-auto text-left">
      <div class="login-page-content">
        <h1 class="mb-3 flama-bold--font">Join Roxie</h1>
        <p class="mb-10 primary--text">Super glad you joined us.</p>

        <ValidationObserver v-slot="{ handleSubmit }" tag="div">
          <v-form @submit.prevent="handleSubmit(submitLoginForm)">
            <ValidationProvider class="mb-10 d-block" mode="eager" tag="label" name="Email" rules="required|email" v-slot="{ errors }">
              <div class="grey-7--text mb-2">Email</div>
              <v-text-field
                outlined
                type="email"
                hide-details="auto"
                v-model="email"
                color="black"
                placeholder="Type your email"
              ></v-text-field>
              <div class="error--text text-left mt-2">{{ errors[0] }}</div>
            </ValidationProvider>

            <ValidationProvider class="mb-10 d-block" mode="eager" tag="label" name="Password" rules="min:8|max:64|required" vid="confirmation" v-slot="{ errors }">
              <div class="grey-7--text mb-2">Your Password</div>
              <v-text-field
                outlined
                type="password"
                hide-details="auto"
                v-model="password"
                color="black"
                placeholder="Type your password"
              ></v-text-field>
              <div class="error--text text-left mt-2">{{ errors[0] }}</div>
            </ValidationProvider>

            <ValidationProvider v-if="isRegisterPage" class="mb-10 d-block" mode="eager" tag="label" name="Confirm password" rules="confirmed:confirmation|required" v-slot="{ errors }">
              <div class="grey-7--text mb-2">Confirm Your Password</div>
              <v-text-field
                outlined
                type="password"
                hide-details="auto"
                v-model="confirmPassword"
                color="black"
                placeholder="Re-type your password"
              ></v-text-field>
              <div class="error--text text-left mt-2">{{ errors[0] }}</div>
            </ValidationProvider>

            <ValidationProvider v-if="isRegisterPage" class="mb-10 d-block" mode="eager" tag="div" name="Terms and conditions" :rules="{required: {allowFalse: false}}" v-slot="{ errors }">
              <v-checkbox
                v-model="terms"
                color="primary"
                hide-details
              >
                <template v-slot:label>
                  <span class="d-flex align-center">
                    I agree to
                  <router-link @click.stop :to="{name: 'TermsOfUsePage'}" class="ml-1 light-blue--text">terms & conditions</router-link>
                  </span>
                </template>
              </v-checkbox>
              <div class="error--text text-left mt-2">{{ errors[0] }}</div>
            </ValidationProvider>

            <v-btn
              dark
              depressed
              type="submit"
              height="auto"
              width="100%"
              color="black"
              class="mb-4 px-4 py-4 login-submit-btn"
            >
              <img class="mr-2" width="24" height="24" :src="require('@/assets/img/icons/ic-arrow-right-white.svg')"
                   alt="">
              {{ isRegisterPage ? 'Sign up for free' : 'Log in'}}
            </v-btn>

            <div class="error--text text-center mt-2" v-if="$store.getters.getErrorMessage">
              {{$store.getters.getErrorMessage}}
            </div>

          </v-form>
        </ValidationObserver>

        <div class="login-with-social mt-10 mb-14">
          <p class="login-with-social-header mb-8 pt-4">
            <span class="white px-4">{{ isRegisterPage ? 'Or sign up with' : 'Or log in with'}}</span>
          </p>
          <div class="d-flex justify-center">
            <a class="login-with-social-item d-flex align-center justify-center mx-2 white" href="#" v-for="(link, idx) in social" :key="idx">
              <img width="30" :src="require(`@/assets/img/login/${link.icon}.svg`)" alt="">
            </a>
          </div>
        </div>

        <p v-if="isRegisterPage" class="grey-7--text">Do you already have an account? <a @click="goToLogin" class="text-decoration-underline">Log in</a> </p>
      </div>
    </div>

    <img :src="require('@/assets/img/login/girls.png')" alt="">

  </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {extend} from 'vee-validate';
import {min, max, confirmed, email, required} from 'vee-validate/dist/rules';
import {checkUserExist} from "../../api/authorizatios.api";

extend('email', {
  ...email,
  message: '{_field_} is not a valid email address',
});

extend('required', {
  ...required,
  message: '{_field_} cannot be blank.',
});

extend('min', {
  ...min,
  message: '{_field_} must have at least {length} symbols.',
});

extend('max', {
  ...max,
  message: '{_field_} Password must have less than {length} symbols.',
});

extend('confirmed', {
  ...confirmed,
  message: 'Passwords do not match.',
});

export default {
  name: 'LoginPage',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      social: [
        {name: 'Google', icon: 'go', url: ''},
        {name: 'Twitter', icon: 'tt', url: ''},
        {name: 'LinkedIn', icon: 'in', url: ''},
        // {name: 'GitHub', icon: 'gt', url: ''},
      ],

      email: this.$store.state.auth.userEmail,
      password: '',
      confirmPassword: '',
      terms: false,
    }
  },
  computed: {
    isRegisterPage() {
      return this.$route.meta.loginType;
    }
  },
  methods: {
    async submitLoginForm() {
      if (this.isRegisterPage) {
        const data = await checkUserExist({
          user: {
            email: this.email
          }
        });

        if (!data.data.success) {
          this.$store.commit('SET_ERROR_MESSAGE', 'This account already exists. Please try again using a different email address.');
        } else {
          this.$store.commit('SET_USER_INFO', {
            email: this.email,
            password: this.password,
            password_confirmation: this.confirmPassword,
          });
          await this.$router.push({ name: 'RegisterQuestions' });
        }

      } else {
        await this.$store.dispatch('LOGIN', {
          email: this.email,
          password: this.password,
        });
      }
    },
    goToLogin() {
      this.$store.commit('SET_ERROR_MESSAGE', '');
      this.$router.push({name: 'Login'});
    }
  },
  beforeDestroy() {
    this.$store.commit('SET_ERROR_MESSAGE', '');
  },
}
</script>
