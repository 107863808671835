var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-page text-right"},[_c('div',{staticClass:"login-page-content-wrap px-4 mx-auto text-left"},[_c('div',{staticClass:"login-page-content"},[_c('h1',{staticClass:"mb-3 flama-bold--font"},[_vm._v("Join Roxie")]),_c('p',{staticClass:"mb-10 primary--text"},[_vm._v("Super glad you joined us.")]),_c('ValidationObserver',{attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitLoginForm)}}},[_c('ValidationProvider',{staticClass:"mb-10 d-block",attrs:{"mode":"eager","tag":"label","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"grey-7--text mb-2"},[_vm._v("Email")]),_c('v-text-field',{attrs:{"outlined":"","type":"email","hide-details":"auto","color":"black","placeholder":"Type your email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',{staticClass:"error--text text-left mt-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"mb-10 d-block",attrs:{"mode":"eager","tag":"label","name":"Password","rules":"min:8|max:64|required","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"grey-7--text mb-2"},[_vm._v("Your Password")]),_c('v-text-field',{attrs:{"outlined":"","type":"password","hide-details":"auto","color":"black","placeholder":"Type your password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"error--text text-left mt-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.isRegisterPage)?_c('ValidationProvider',{staticClass:"mb-10 d-block",attrs:{"mode":"eager","tag":"label","name":"Confirm password","rules":"confirmed:confirmation|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"grey-7--text mb-2"},[_vm._v("Confirm Your Password")]),_c('v-text-field',{attrs:{"outlined":"","type":"password","hide-details":"auto","color":"black","placeholder":"Re-type your password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('div',{staticClass:"error--text text-left mt-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.isRegisterPage)?_c('ValidationProvider',{staticClass:"mb-10 d-block",attrs:{"mode":"eager","tag":"div","name":"Terms and conditions","rules":{required: {allowFalse: false}}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"color":"primary","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"d-flex align-center"},[_vm._v(" I agree to "),_c('router-link',{staticClass:"ml-1 light-blue--text",attrs:{"to":{name: 'TermsOfUsePage'}},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("terms & conditions")])],1)]},proxy:true}],null,true),model:{value:(_vm.terms),callback:function ($$v) {_vm.terms=$$v},expression:"terms"}}),_c('div',{staticClass:"error--text text-left mt-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_c('v-btn',{staticClass:"mb-4 px-4 py-4 login-submit-btn",attrs:{"dark":"","depressed":"","type":"submit","height":"auto","width":"100%","color":"black"}},[_c('img',{staticClass:"mr-2",attrs:{"width":"24","height":"24","src":require('@/assets/img/icons/ic-arrow-right-white.svg'),"alt":""}}),_vm._v(" "+_vm._s(_vm.isRegisterPage ? 'Sign up for free' : 'Log in')+" ")]),(_vm.$store.getters.getErrorMessage)?_c('div',{staticClass:"error--text text-center mt-2"},[_vm._v(" "+_vm._s(_vm.$store.getters.getErrorMessage)+" ")]):_vm._e()],1)]}}])}),_c('div',{staticClass:"login-with-social mt-10 mb-14"},[_c('p',{staticClass:"login-with-social-header mb-8 pt-4"},[_c('span',{staticClass:"white px-4"},[_vm._v(_vm._s(_vm.isRegisterPage ? 'Or sign up with' : 'Or log in with'))])]),_c('div',{staticClass:"d-flex justify-center"},_vm._l((_vm.social),function(link,idx){return _c('a',{key:idx,staticClass:"login-with-social-item d-flex align-center justify-center mx-2 white",attrs:{"href":"#"}},[_c('img',{attrs:{"width":"30","src":require(("@/assets/img/login/" + (link.icon) + ".svg")),"alt":""}})])}),0)]),(_vm.isRegisterPage)?_c('p',{staticClass:"grey-7--text"},[_vm._v("Do you already have an account? "),_c('a',{staticClass:"text-decoration-underline",on:{"click":_vm.goToLogin}},[_vm._v("Log in")])]):_vm._e()],1)]),_c('img',{attrs:{"src":require('@/assets/img/login/girls.png'),"alt":""}})])}
var staticRenderFns = []

export { render, staticRenderFns }